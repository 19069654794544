import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			api: 'lsajkdh921SAD-8uFDGDas4',
			subject: '',
			name: '',
			email: '',
			copy: false,
			message: '',
			url: ''
		}
	}

	render() {
		return(
			<form id="contact" method="POST" className="col-12 mt-4 mb-5" onSubmit={this.handleSubmit.bind(this)}>
				<div className="row">
					<div className="col-12 mb-3">
						<input type="text" name="name" id="name" required placeholder="Your name" aria-label="Your name" value={this.state.name} onChange={this.onNameChange.bind(this)}/>
					</div>
					<div className="col-12 mb-3">
						<input type="text" name="subject" id="subject" required placeholder="Your subject" aria-label="Your subject" value={this.state.subject} onChange={this.onSubjectChange.bind(this)}/>
					</div>
					<div className="col-12 mb-3">
						<input type="email" name="email" id="email" required placeholder="Your e-mail" aria-label="Your e-mail" value={this.state.email} onChange={this.onEmailChange.bind(this)}/>
					</div>
					<div className="col-12 mb-3">
						<input type="checkbox" id="copy" name="copy" checked={this.state.copy} onChange={this.onCopyChange.bind(this)}/>
						<label htmlFor="copy">Email me a copy</label>
					</div>
					<div className="col-12 mb-3">
						<textarea name="message" id="message" placeholder="Write your message here" aria-label="Write your message here" required rows="6" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
					</div>
					<div className="col-12 mb-3">
						<div className="actions">
							<button type="submit" id="contact-submit" className="primary">Send</button>
						</div>
						<div id="contact-alert" className="alert alert-danger py-1 px-3 d-flex align-items-center visually-hidden" role="alert">

						</div>
					</div>
				</div>
			</form>
		);
	}

	onSubjectChange(event) {
		this.setState({subject: event.target.value})
	}
	onNameChange(event) {
		this.setState({name: event.target.value})
	}
	onEmailChange(event) {
		this.setState({email: event.target.value})
	}
	onCopyChange(event) {
		this.setState({copy: !this.state.copy})
	}
	onMessageChange(event) {
		this.setState({message: event.target.value})
	}
	handleSubmit(event) {
		event.preventDefault();
		document.getElementById("contact-submit").innerHTML = "Loading...";
		fetch(this.props.url+'/mail/send', {
			method: "POST",
			body: JSON.stringify(this.state),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then(
			(response) => (response.json())
		).then((response)=> {
			if (response.status === 'success') {
				document.getElementById("contact").innerHTML = "<p>Thank you for contacting us! We will get in touch with you as soon as possible.</p>";
			} else if(response.status === 'fail') {
				document.getElementById("contact-alert").innerHTML = "An error occured while sending, try again or contact us per email.";
				document.getElementById("contact-alert").classList.toggle("visually-hidden");
				document.getElementById("contact-submit").innerHTML = "Send";
			}
		})
	}
}

const IndexPage = () => {

	var seo = { metaTitle:'Contact page | Dapit', metaDescription:"Dapit's contact information with a contact form to get in touch with Dapit.", shareImage:"/images/LandingPage-Dapit.png"};

	const { site } = useStaticQuery(query);

	return (
		<Layout pageen="/contact" pagelb="/kontakt" seo={seo}>
			<section className="container-fluid block contact mt-5">
				<div className="row mt-4">
					<div className="col-12 col-lg-5 title">
						<h1>Social media</h1>
						<p>You find me on the following platforms.</p>
						<div class="social d-flex justify-content-left mb-5">
							<a href="http://www.dapit.lu" target="_blank">
								<i className="fas fa-torii-gate"></i>
							</a>
							<a href="https://t.me/daptele" target="_blank">
								<i className="fab fa-telegram-plane"></i>
							</a>
							<a href="https://t.me/joinchat/T90sf6fhN8BTLZE6" target="_blank">
								<i className="fas fa-rss-square"></i>
							</a>
							<a href="https://www.instagram.com/dapitinsta/" target="_blank">
								<i className="fab fa-instagram"></i>
							</a>
						</div>
						<h1>Contact</h1>
						<p>Dylan Mertz</p>
						<p>00352 621661345<br/>info@dapit.lu</p>
					</div>
					<div className="col-12 col-lg-7 title mb-5">
						<h1>Get in touch</h1>
						<p>Write your message here and i will contact you very soon.</p>
						<App url={site.siteMetadata.parentUrl}/>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;

const query = graphql`
	query {
		site {
			siteMetadata {
				imagePrefix
				parentUrl
			}
		}
	}
`;
